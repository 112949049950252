

  label {
	display: inline-block;
	// padding: 10px 20px;
	background-color:#ffffff;
	cursor: pointer;
  }

  
  input[type="radio"]:checked + label {
    
     color:#e97418 !important;
	

  }
//   input[tabindex]:focus .checked + label{
// 	color:#fbcb0c!important;
//      outline: none;

//   }
  
  /* Hide all tab contents by default */
  .tab-label-vim {
	display: none;
  }
  
  /* Show the active tab content */
  input[type="radio"]:checked + label + .tab-label-vim1 {
	display: block;
  }
  .cost{
    //padding-top: 10px;
    text-align: center !important;
    font-weight:bolder !important;
    float: right !important;
	font-size: medium !important;
}
.button-62 {
	background: linear-gradient(to bottom right, #2554C7, #2554C7);
	border: 0;
	border-radius: 12px;
	color: #FFFFFF;
	cursor: pointer;
	display: inline-block;
	font-family: -apple-system,system-ui,"Segoe UI",Roboto,Helvetica,Arial,sans-serif;
	font-size: 16px;
	font-weight: 500;
	line-height: 2.5;
	outline: transparent;
	padding: 0 1rem;
	text-align: center;
	text-decoration: none;
	transition: box-shadow .2s ease-in-out;
	user-select: none;
	-webkit-user-select: none;
	touch-action: manipulation;
	white-space: nowrap;
  }
  #Agricultural-list{
  white-space: nowrap;
  border-right:none ;
  }

	//   .manager_img{
// 	width:300px;
// 	height: 300px;
//   }
.manager_section{
	background-color: #e8f1ff;
	height: 400px;
	margin: 30px 20px;
	border-radius: 6px;
	box-shadow: 1px 2px 15px #ccc;
}
.manager_content_info{
	padding: 117px 96px 117px 11px;
}
  .about-us-md{
	width: 100%;
    height: auto;
    padding: 0 108px;
    
  }


.about_info_content{
	text-align: justify;
}


  .about-us-img1{

	width: 100%;
    margin-top: 0%;
    margin-left: 1%;

  }

  .about_info1{
	margin: 20px 0;
  }
  

  .About{
	margin-left:42px;
    margin-top:1px;
  }
  .about-us-img2{
	width: 98%;
    // margin-left: 16%;
    // margin-top: 4%;

  }
  .About12{
	
    margin-left: 5%;
    margin-top: 7%;


  }

#logo-images_cardpage{
	width: 51%;
}

.social-icons{
	display: flex;
	margin: 0px 28px;
}

#tab-label-vim_1{
	white-space: nowrap;
	border-right:none 
}
.reach_us_out
{
	display:block;
	margin-left: 6px;
	color: white;
                            /* margin-right: auto; */
                            float: right;
							position: absolute;
							top: 45px;
	cursor: pointer;
}
.reach_us
{
	display: none;
}
.sure_we_can{


    position: absolute;
    margin-left: 73.1%;
    margin-top: 1px;
    color: #fff;
    font-family: cursive;
    font-size: 36px;
}
#Contact_Persons{
	position: relative;
    margin-left: 350px;
    font-weight: bold;
    font-size: 15px;
}
#Babu_contact{
	position: relative;
    margin-left: 10%;
}
#Arvind_babu_contact{
	position: absolute;
    margin-left: 55.6%;
    margin-top: -10%;

}
#Gautham_babu_contact{
	position: relative;
    margin-top: 5%;
	margin-left: 10%;
}
#Srinivasan_contact{
	position: relative;
    margin-left: 58%;
    margin-top: -10.5%;
}
.social-icons{
	display: flex;
    position: relative;
    // left: 1%;
	margin-top: 2%;
}

.vimpro_link{
	margin:90px 0;
	height: 503px;
}
.vimpro_video{
	height: 100% !important;
	position: relative;
left:328px;
object-fit: contain;
bottom:29px;
width: 160% !important;
}
  .button-63 {
	background: linear-gradient(to bottom right, #2554C7, #2554C7);
	border: 0;
	border-radius: 12px;
	color: #FFFFFF;
	cursor: pointer;
	display: inline-block;
	font-family: -apple-system,system-ui,"Segoe UI",Roboto,Helvetica,Arial,sans-serif;
	font-size: 16px;
	font-weight: 500;
	line-height: 2.5;
	outline: transparent;
	padding: 0 1rem;
	text-align: center;
	text-decoration: none;
	transition: box-shadow .2s ease-in-out;
	user-select: none;
	-webkit-user-select: none;
	touch-action: manipulation;
	white-space: nowrap;
	position: relative;
    top:1px;
  }
  .bullet_point
  {
    display:none;
  }
.vimpro_head{
  font-weight: 750;
  font-size: 14px;
}
.vimpro_foot{
  font-family: math;
}
  .button-62:not([disabled]):focus {
	box-shadow: 0 0 .25rem rgba(0, 0, 0, 0.5), -.125rem -.125rem 1rem rgba(4, 151, 166,0.5), .125rem .125rem 1rem rgba(255, 154, 90, 0.5);
  }
  
  .button-62:not([disabled]):hover {
	box-shadow: 0 0 .25rem rgba(0, 0, 0, 0.5), -.125rem -.125rem 1rem rgba(4, 151, 166,0.5), .125rem .125rem 1rem rgba(255, 154, 90, 0.5);
  }
.carience-container{
	display: grid;
	grid-template-columns: repeat(3, 1fr);
}
.carience-card{
	padding: 10px;
	border-radius: 10px;
	//border:1px solid black;
	border: 2px solid #feae01;
	height: 480px;
    width: 300px;
	-webkit-box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.75);
   // box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.75);
   box-shadow: 0 2px 14px #0003, 0 4px 14px #cf343430;
}
.carience-image-container{
	display: flex;
    justify-content: center;
    width: 100%;
    height: 250px;
}
.colour-select-container{
	display: flex;
	padding-top: 10px;
	padding-bottom: 10px;
}
.colour-select-image{
	object-fit: contain;
	width: 20%;
	margin-right:5px
}
.colour-image{
	width: 100%;
    border: 1px solid;
	height: 60px;
}
.detail-colour-image{
	width: 70% !important;
    border: 1px solid;
	height: 80px!important;
}
.colour-image.active{
	border:1px solid red;
}
.carience-listing-price{
	font-size:18px;
	color: black;
	font-weight: 700;
}
.carience-strikethrough{
	font-size: 16px !important;
	margin-left: 5px !important;
	text-decoration: line-through;
	color: grey !important;
}
.prod-detail-strike{
	font-size: 10px !important;
	margin-left: 5px !important;
	text-decoration: line-through;
	color: grey !important;
	margin-top: 5px;

}
.carience-discount-percent{
	margin-left: 7px;
    font-size: 12px;
    align-items: center;
    display: flex;
    color:#00A36C;
}
.dropdownstyle1{
    padding-left: 10px;
    margin-bottom: 5px;
    padding-right: 10px;
	border-radius: 0px !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
	border:2px solid black
}
::-ms-input-placeholder {
	color: #808080 !important;
	opacity: 0.5 !important;
}

body {
	// font-family: "Inter-Medium";
	background-color: $theme-bg;
}
a,
a:hover {
	color: $font-color1;
	text-decoration: none;
}
ul {
	padding-left: 0px;
	margin: 0;
}
li {
	list-style: none;
}
// .vimpro_list{
// 	color: blue !important;
// }

// .vimpro_list:hover{
// 	color:#fbcb0c;
    
// }
// .vimpro_list:active{
// 	color:#fbcb0c!important
    
// }


// .vimpro_list[tabindex]:focus {
// 	color:#fbcb0c!important;
//     outline: none;
// }
.vimpro_list{
	
	font-size: 17px !important;
}
h3,
p {
	// font-family: "Inter-Regular";
}
.container_2{

	padding: 1px 1px ;
	

}
.product-name {
//   font-family: 'proxima-nova';
}
h6 {
	// font-family: 'proxima-nova';
}
h4 {
	// font-family: "Inter-Medium";
	&.bold {
		// font-family: "Inter-ExtraBold";
	}
	&.sm-bold {
		// font-family: "proxima-nova";
		font-size: 14px !important;
	}
}
.pb-50 {
	padding-bottom: 50px;
}

ul {
	&.two-column{
		columns: 2;
		-webkit-columns: 2;
		-moz-columns: 2;
		li{
			font-size: 14px;
			.list-label {
				color: $textgray;
				margin-right: 15px;
				min-width: 105px;
			}
			.list-value {
				color:$primary-color;
			}
		}
	
	}
	&.three-column{
		columns: 3;
		-webkit-columns: 3;
		-moz-columns: 3;
		li{
			font-size: 14px;
			.list-label {
				color: $textgray;
				min-width: 105px;
			}
			.list-value {
				color:$primary-color;
			}
		}
	
	}
  }
#precies12{
	max-width: 540px;
	height: 330px;
	border: none!important;
}
#precise_size{
	margin-top:3vh;
}
#exoffer_12{
	max-width: 100%;
	height: 300px;
	border: none!important;
	margin-bottom:36px;
	
}
#exoffer-margin{
	margin-left: 8.5%;
	// margin-top:645px; 
}


.vacuum_height{
	margin: 26px 0 0 25px;
	// height:92%;
	width:70%
}
/** Title **/
.content-title {
	text-align: center;
	padding: 60px 0px 30px 0px;
	.title-box {
		.title {
			display:inline-block;
			position: relative;
			// font-family: 'proxima-nova';
			margin-bottom: 5px;
			font-size: 1.8rem !important;
			color: #FBCB0C;
			// span {
			// 	background-color: $theme-bg;
			// }
			// &:after {
			// 	content:"";
			// 	width:100%;
			// 	position: absolute;
			// 	bottom:2px;
			// 	height:2px;
			// 	background:#000;
			// 	left:0;
			// 	z-index: -1;
			// }

		}
		.sub-title {
			// font-family: 'Inter-Regular';
			color:#a7a7a7;
			font-size: 1.3rem !important;
		}
	}
}


.row-fluid {
	display: flex;
	flex-flow: wrap;
}
.sub-heading{
	text-transform: uppercase;
    font-size: 17px;
    // font-family: 'Inter-Medium';
    margin-bottom: 20px;
	cursor: pointer;
}


/**Product box one **/
.product-box-one {
	.product-detail {
		background-color: #fff;
		padding:10px;
		position: relative;
		border: 2px solid #eceaea;
		.product-name {
			padding-right:30px;
		}
		.product-option {
			.wishlist {
				.add-to-wishlist {
					position: absolute;
					top: 0px;
					right: 0px;
					font-size: 22px;
				}
			}
		}
	}
}
/**product**/

.product-name {
	font-size: 18px;
}
h6 {
	&.product-name {
		font-size: 19px;
	}
}

/***Listing**/
.collection-wrapper {
    .collection-content {
      background-color: #fff;
    }
    padding-top: 30px;
    padding-bottom: 30px;
    .collection-top {
      padding-top: 15px;
      padding-bottom: 15px;
    }
    .collection-filter {
      background-color: #fff;
      .filter-top {
        border-bottom: 1px solid #dddddd;
        .filter-clear {
          padding: 10px;
          display: flex;
          justify-content: space-between;
        }
      }
      .layerd-navigation {
        padding: 10px;
        // font-family: "Inter-Regular";
		border-bottom: 1px solid #dddddd;
		.collection-collapse-block {
			margin-bottom: 10px;
			
		.collection-collapse-block-content {
			padding-left: 30px;
			
			.options {
                margin: 8px 0px 8px 0px;
                input {
                    margin-right: 10px;
                }
                label{
                    margin-bottom: 0px;
                    font-size: 15px;
                    .count {
                        color: #afabab;
                        font-size: 14px;
                    }
                }
            }
		}
	  }
	}
    }
  
    .col-grid-box {
      width: 100%;
      background: #fff;
      padding: 0px 10px 0px 10px;
    }
  
    .sortby {
      .input-group-text {
        background-color: #fff;
        border-radius: 0;
        border-right: 0;
      }
      .sortby-options {
        border-left: 0px;
        border-radius: 0;
      }
    }
  }
  /** Product Details**/
  
.product-details-wrapper {
    .details-top {
        padding: 15px 0px 15px 0px;
    }
    .slider-nav {
        max-width: 400px;
        min-width: 300px;
        position: absolute;
        bottom: 50px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 9;
        
    .owl-thumb {
        img{
            opacity: 0.5;
        }
        background-color: #000;    
        cursor: pointer;
        &:hover {
            background-color: transparent;
            border:2px solid #f7f1f1;
            img {
                opacity: 1;
            }
        }
        &.active {
            
            border:2px solid #f7f1f1;
            background-color: transparent;
            img {
                opacity: 1;
            }
        }
    }
    }
    .product-all-details {
        .product-name {
            font-size: 30px;
        }
    }

    .details-wrapper {
        .product-all-details {
            background-color: #fff;
            padding: 0px 15px 15px 15px;
            .price {
				font-size: 24px;
			}
            .row {
                padding:15px;
                .col-left {
                    flex: 1;
                    padding-right: 15px;
                }
                .col-right{
                    min-width: 250px;
                     .add-to-cart {
                        margin-bottom: 30px;
                    }
                    .delivery-option {
                        margin-top: 30px;
                        h6 {
                            .fa {
                                    font-size: 22px;
                                    -webkit-transform: scaleX(-1);
                                    transform: scaleX(-1);
                            }
						}
						.input-group {
							input {
								border-right:0px;
							}
							.input-group-text {
								background-color: #fff;
								border-left:0;
								color:$primary-color;
							}
						}
						
                    }
                   
                }
            }
            
            .description{
                padding-bottom: 15px;
            }
            .product-addtional-details {
                position: relative;
                padding-top: 15px;
                &::before {
                    content: "";
                    position: absolute;
                    width: calc(100% - 198px);
                    height: 2px;
                    background: #707070;
                    opacity: 0.2;
                    box-shadow: 0;
                    top: -10px;
                }
            }
        }
        
    }
    
}

.ng5-slider {
	.ng5-slider-pointer {
		cursor: pointer;
		width: 20px!important;
		height: 20px!important;
		top: -8px!important;
		background-color: #fff !important;
		z-index: 3;
		border-radius: 16px!important;
		box-shadow: 2px 3px 9px 2px rgba(0,0,0,0.56);
		-webkit-box-shadow: 2px 3px 9px 2px rgba(0,0,0,0.56);
		-moz-box-shadow: 2px 3px 9px 2px rgba(0,0,0,0.56);
	}

	 .ng5-slider-span:focus{
		outline: 0;
	}
	.ng5-slider-pointer:after {
		display: none !important;
	}
	.ng5-slider-selection {
		z-index: 2;
		background: $primary-color !important;
		border-radius: 2px;
	}
}

/** Pages **/

.homepage-banner-slider {
  .owl-theme {
    .owl-dots { 
      position: absolute;
			bottom: 15px;
			left: 50%;
	}
	.owl-next,
	.owl-prev {
		position: absolute;
		top:50%;
		transform: translateY(-50%);
		background-color: $theme-bg !important;
		border: 0px !important;
		.fa {
			font-size: 30px;
		}
	}
	.owl-next{
		right:0;
		background:$theme-bg;
		width:20px;
	}
	.owl-prev{
		left:0;
		background:$theme-bg;
	}
  }
}
.rounder-corner-nav {
	&.top-nav-right{
		.owl-theme {
			.owl-nav {
				position: absolute;
				top: -70px;
				right: 0;
			}
	}
}
&.top-nav-bg-white{
	.owl-theme {
		.owl-nav {
			margin-top:0px;
			.owl-prev,
			.owl-next {
					background: #fff;
					border: 0px;
					.fa {
						color:#000;
						
					}
			}
		}
}
}
	.owl-theme {
		.owl-nav {
			margin-top:0px;
			.owl-prev,
			.owl-next {
				border-radius: 20px;
				width: 40px;
				height: 40px;
				background: transparent;
				border: 2px solid #b7b6b5;
				.fa {
					color:#b7b6b5;
					font-size: 26px;
				}
			}
		}
	}
}
.rotate-dot{
	.owl-theme {
		.owl-dots {
		
			transform: translateX(-50%);
			[class*="owl-"] {
				width: 25px;
				height: 25px;
				transform: rotate(130deg);
				margin: 0px 6px;
				border: 1px solid transparent;
				&.active {
					border: 1px solid $primary-color;
					span {
						background-color: $primary-color;
					}
				}

				span {
					width: 12px;
					height: 12px;
					border-radius: 0;
					margin: 5px 5px;
				}
			}
		}
	}
}


.featured-collections {
  .tab-image {
          min-height: 300px;
          text-align: center;
          position: relative;
          margin-bottom: 30px;
          cursor: pointer;
          overflow: hidden;
          position: relative;
          .background-image {
            height: 100%;
            width: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            -webkit-transition: all .5s;
            -moz-transition: all .5s;
            -o-transition: all .5s;
            transition: all .5s;
                min-height: 300px;
                &:hover {
                  transform: scale(1.2);
                }
          }
          .bg-content{
            position: absolute;
            top:50%;
            left:50%;
			transform: translate(-50%, -50%);
			 color:#fff;
            font-size: 25px;
           
			  p{
				  font-size: 15px;

			  }
           
          }
          
  }
}


.best-products {
  background: #fff;
  padding-top: 50px;
  padding-bottom: 30px;
  .title {
	  text-align: center;
	  padding-bottom: 20px;
	   h2 {
		// font-family: 'proxima-nova';
	}
  }
  .column {
      
      -webkit-flex: 1; /* Safari */
      -ms-flex: 1; /* IE 10 */
      flex: 1; /* Standard syntax */
      
      &.grid-image {
          -webkit-flex: 2; /* Safari */
          -ms-flex: 2; /* IE 10 */
          flex: 2; /* Standard syntax */
      }
      &.single-image {
          border: 1px solid #c5c5c5;
          margin-bottom:20px;
          text-align: center;
          .trers-single-image {
              img {
                  min-width: 300px;
              }
          }
          h4 {
              font-size: 20px;
              text-transform: uppercase;
          }
      }
      .tyers-grid {
          display: flex;
          flex-flow: wrap;
          margin: 0;
          li {
              width:calc(50% - 20px);
              border: 1px solid #c5c5c5;
              margin: 0px 20px 20px 0px;
              padding:10px;
              display: flex;
			  align-items: center;
			  .trers-grid-image {
				  img {
					  padding: 10px;
				  }
			  }
              a {
				cursor: pointer;
				display:block;
              }
              &:hover {
                border-color: var(--theme-deafult);
              }
              h4 {
                  font-size: 20px;
                  text-transform: uppercase;
              }

          }
      }
    
      
  }
 
}
.mobile-cart{
	display: none !important;
}

.mobile-only{
	display: none !important;
}
.home-featured-collections-title{
	font-size: 1.4rem; 
	text-transform: uppercase; 
	font-weight: bold;
	color:black;
	text-align: center;

}
.home-featured-collections{
	margin-left:100px;
}
.deera-details-btn{
	width: 40% !important;
	margin-left: 30px !important;
}
.viewdetails-btn{
	padding: 5px 20px !important;
	border: none !important;
	font-size: 16px !important;
}
.home-vimpro1{
	display: grid;
	grid-template-columns: repeat(3,1fr);
	border:1px solid #ccc;
	width:80%;
	margin-left:10%;
	padding:15px;
}

.new_arr1{
	font-size: 28px!important;
	text-align: center;
	font-weight: bold;
	white-space: nowrap;
	position: relative;
	// margin-top: 21px;
    // margin-left: 7px;
  }


@media (min-width:1200px) and (max-width:1350px){
	
	.sure_we_can{
		position: absolute;
		margin-left: 73.7%;
		margin-top: 4px;
		color: #fff;
		font-family: cursive;
		font-size: 36px;
	}
	.vimpro_video{
		height: 100% !important;
		position: relative;
		left:341px;
		object-fit: contain;
		bottom:29px;
	width: 160% !important;
	}
	#Contact_Persons{
		position: relative;
		margin-left:297px;
		font-weight: 700;
		font-size: 17px;
	}
  }
@media screen and (max-width: 600px){
	.reach_us_out
{
	display:none;
}
.reach_us
{
	margin-right: 6px;
	display:block;
	color: #e97418;
    float: right;
    top: -2px;
    margin-top: -13px;
    position: relative;
	cursor: pointer;
}
	.white_vimpro{
		color: white!important;

	}
	#logo-images_cardpage{
		width: 165px;
		height: 40px;	
	}
	#Contact_Persons{
		position: relative;
		margin-left: 29%;
		font-weight: bold;
		font-size: 15px;
	}
	#Srinivasan_contact {
		position: relative;
		margin-left: 10%;
		margin-top: 5.5%;
	}
	#Arvind_babu_contact {
		position: relative;
		margin-left: 9.6%;
		margin-top: 3%;
	}
	.sure_we_can{

	    position: absolute;
    margin-left: -39% !important;
    margin-top: 2px;
    color: #fff;
    font-family: cursive;
    font-size: 21px;
	}
	.social-icons{
		display: flex;
		margin: 0 50px;
	}

	.sure_we {
	    font-family: Brush Script MT,cursive;
    position: absolute;
    margin-left: -42px;
    margin-top: -4px;
	color: #e97418;
    font-size: 26px;
	}

	.vimpro_link{
		margin:60px 0;
		height: 90px;
	}
	.vimpro_video{
		height: 100% !important;
		position: relative;
	left:90px;
	object-fit: contain;
	bottom:29px;
	width: 200% !important;
	// margin-top: 30px;
	margin: 20px 0;
	}
	.manager_section {
		background-color: #e8f1ff;
		height: 515px;
		margin: 16px 8px;
		border-radius: 6px;
		box-shadow: 1px 2px 15px #ccc;
	}
	.manager_content_info{
		padding:20px;
	}
	.about_info_content{
		line-height: 27px !important;
	}
	.manager_content_info h1{
		font-size: 25px;
	}
	.manager_content_info h4{
		font-size: 20px;
	}
	.about_info_content p{
		margin-top: 18px;
		text-align: justify !important;
	}



	.home-vimpro1{
	
		
	grid-template-columns: repeat(1,1fr);
    border: 1px solid #ccc;
    width: 98%;
    margin-left:1%;
    padding: 15px;
    align-content: stretch;

	}
	.vimpro-icon-out {
		border: none!important;
		width: 25%;
		height: 58%;
		padding: 10px;
		border-radius: 50px;
		margin-left:0%!important;
	}
	.vimpro-home-icon {
		width: 100%!important;
	}
	.border_mobile{
		border-right: none!important;
		
	}
	.home-vimpro2 {
		display: grid!important;
		grid-template-columns: repeat(1,1fr)!important;
		width: 80%;
		margin-left: 10%;
		padding: 15px;
	}

  }

.home-vimpro2{
	display: grid;
	grid-template-columns: repeat(3,1fr);
	width:80%;
	margin-left:10%;
	padding:15px;
}
.home-vimpro-flex{
	display: flex;
}
.home-vimpro-block{
	display: block;
}
.vimpro-home-icon{
	width:100%;
}
.vimpro-icon-out{
	border: 1px solid #ccc;
    width: 25%;
    padding: 10px;
    border-radius: 50px;
}
.vimpro-icon-out2{
	border: 1px solid #ccc;
    width: 20%;
    padding: 10px;
    border-radius: 50px;
	margin-left:40%;
	background-color: $secondary;
}
.t1-vimpro{
	display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    font-weight: 700;
}
.t2-vimpro{
	text-align: center;
	padding: 15px;
}
.vimpro-header{
	border:none !important;
	background-color: $secondary !important;
}
.vimpro-nav{
	border-bottom: 2px solid #ccc !important;
	background-color: $secondary !important;
}
.vimpro-searchbtn{
	border-radius: 0px 50px 50px 0px !important;
    width: 150%;
}
.vimpro-subbtn{
	border-radius: 0px 50px 50px 0px !important;
	padding: 8px;
	background-color: $secondary;
	border:1px solid #ddd;
	width: 25% !important;
	margin-top: 2%;
    margin-bottom: 2%;
	height: 60%
}

.vimpro-sub{
	background-color: #eee;
	display: flex;
    padding: 3%;
	
}
.vimpro-social{
	background-color: black;
    border-radius: 50px;
    padding: 10px;
	margin: 10px 9px;
    height: 45px;
    width: 46px;
    display: flex;
    justify-content: center;
    align-items: center;

}



.vimpro-container1{
	display: grid;
	grid-template-columns: repeat(4,1fr);
}

.vimpro-card{
	border:none;
    border-radius: 10px;
    // width: 100%!important;
    min-width: 80%;
    max-width:93%;
    min-height: 30%;
    max-height:30%;
	box-shadow: 0 2px 3px #95abdd38, 0 4px 29px #95abdd38;
}
.vimpro-img{
	height: 80%;
	display: flex;
	justify-content: center;
	box-shadow: 0 2px 3px #95abdd38, 0 4px 29px #95abdd38;
}
.vimpro-title{
	color: #2554c7;
}
.foot-disc{
	width:30%;display: flex; align-items: center;justify-content: center;position: relative;bottom: 11px;cursor: pointer;
}
.buttonupfun{
	padding: 5px !important;
}
@media screen and (max-width:600px) {
	.foot-disc{
		width:90%;display: flex; align-items: center;justify-content: center; margin-top: 20px;cursor: pointer;
	}
	.vimpro-card{
		min-width: 0px !important;
        max-width:100% !important;
        min-height: 0px !important; 
        max-height:133px !important;
        padding: 0px;
        width: 100%;
        display: flex;
        box-shadow: 0px 0px 10px 4px rgba(0,0,0,0.25);
        margin-bottom: 10px;
	}
	.buttonupfun{
		padding: 2px!important;
		width: 21px;
	}
	.vimpro-title{
		color: #2554c7;
	}
	.star-height{
		height: 25px !important;
	}
	.vimpro-social{
		background-color: black;
		border-radius: 50px;
		padding: 10px;
		margin: 1%;
		height: 30px;
		width: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
	
	}
	.vimpro-price{
		font-size: 15px !important;
	}
	.vimpro-img{
		height: 100%;
		width: 80%;
		display: flex;
		justify-content: center;
	}
	.vimpro-sub{
		background-color: #eee;
		display: block;
		padding: 3%;
		
	}
	.vimpro-subbtn{
		border-radius: 0px 50px 50px 0px !important;
		padding:9px;
		background-color: $secondary;
		border:1px solid #ddd;
		width: 35%!important;
		height: 48%;
		margin-bottom: 2%;
	}
	.vimpro-container1{
		display: grid;
		grid-template-columns: repeat(1,1fr);
		
	}
	.rep_c{
		position: relative;
		left: -5px!important;
	}
	.home-featured-collections{
		margin-left: 0px !important;
		width: 120%;
	}
	.btn-width-48{
		width:48% !important;
	}
	.deera-m-b-5{
		margin-bottom: -5%;
	}
	.deera-hide{
		display: none !important;
	}
	.deera-align-checkoutcart{
		left: 25%;
    	position: relative;
	}
	.deera-details-btn{
		width: 40% !important;
		margin-left: 0px !important;
	}
	.viewdetails-btn{
		display: none !important;
	}

	.featured-collections {
		.tab-image {
				min-height: 104px !important;
				height:100px;
				text-align: center;
				position: relative;
				margin-bottom: 30px;
				cursor: pointer;
				overflow: hidden;
				position: relative;
				.background-image {
					position: relative !important;
				  min-height: 100px;
				  width: 120%;
				  margin-left: -10%;
				 
				  background-repeat: no-repeat;
				  -webkit-transition: all .5s;
				  -moz-transition: all .5s;
				  -o-transition: all .5s;
				  transition: all .5s;
					  &:hover {
						transform: scale(1.2);
					  }
				}
				.bg-content{
				  position: absolute;
				  top:50%;
				  left:50%;
				  transform: translate(-50%, -50%);
				   color:#fff;
				  font-size: 25px;
				 
					p{
						font-size: 15px;
					}
				 
				}
				
		}
	  }

	  .rounder-corner-nav {
		&.top-nav-right{
			.owl-theme {
				.owl-nav {
					position: absolute;
					top: -70px;
					right: 0;
				}
		}
	}
	&.top-nav-bg-white{
		.owl-theme {
			background-color: transparent !important;
			.owl-nav {
				margin-top:0px;
				background-color: transparent !important;
				.owl-prev,
				.owl-next {
						background: transparent !important;
						border: 0px;
						.fa {
							color:#000;
							
						}
				}
			}
	}
	}
		.owl-theme {
			.owl-nav {
				margin-top:0px;
				z-index: -100;
				position: absolute;
				top:45%;
				display: flex;
				width: 100%;
				text-align: center;
				justify-content: center;
				top:45%;
				.owl-next {				
					position: relative;
					margin-left: 85%;
					width: 40px;
					height: 40px;
					background: transparent;
					border: none !important;
					background-color: transparent !important;
					.fa {
						color:black;
						font-size: 40px;
					}
				}
				.owl-prev{
					position: relative;
					margin-left: -5%;	
					width: 40px;
					height: 40px;
					background: transparent;
					border: none !important;
					background-color: transparent !important;
					.fa {
						color:black;
						font-size: 40px;
					}
				}
			}
		}
	}

	.mobile-cart{
		display: flex !important;
		float:right;
	}

	.desktop-carousel{
		display: none !important;
	}
	.desktop-only{
		display: none !important;
	}
	.mobile-only{
		display: block !important;
	}
	.mobile-options{
		position: absolute !important;
    	margin-top: -62px !important;
    	margin: 0;
    	margin-left: 160px;
    	left: 0;
    	border: none !important;
	}
	.mob-eye{
		margin-left:40px
	}
	.mob-second{
		margin-top:-31px
	}
	.forgot-link{
		margin-top:-26px !important
	}
	.forgot-link-checkout{
		margin-top:-45px !important
	}
	.back-btn{
		width:35% !important;
		margin-left:65%;
	}

	
	.mob-logo{
		transform: scale(.9);
		top: 5px!important;
		left: 15%;
		position: fixed;
		z-index: 3;
		width: 50%;
		object-fit: contain;
	}
	.home-featured-collections-title{
		font-size: 1rem; 
	}
}
@media screen and (max-width:391px) {
	.home-featured-collections-title{
		font-size: 0.9rem; 
	}
	
}
@media screen and (max-width:360px) {
	.home-featured-collections-title{
		font-size: 0.8rem; 
	}
	
}
@media  screen and (min-width:601px) {
	.logo-image{
		margin-left:3%
	}
	.avail_colors{
		font-family: cursive;
		  margin-top: 26px;
		  margin-bottom: 24px;
		  display: flex;
		  align-items: baseline;

	   }
	   .avl_label{
		font-weight: 700;
	   }    
	   .avl_text{
		font-size: 15px;
	  }
	   .vimpro_variable_false{
		display:flex;margin-bottom: 6px;
	   }
}

@media (min-width: 360px) and (max-width: 600px) {
    .bestgifts-arrival{
      justify-content: left;
   }
   .vimpro_list{
	
	font-size:8.3px !important;
}
#tab-label-vim_1{
	white-space: nowrap;
	border-right:none !important
}
.name-form{
	margin-top: 7px !important;
}

}


@media (max-width: 1920px)  {
	.bestgifts-banner{
		height: 45vh; 
		border: 0px solid white;
		width: 100%;
		background-size: cover;
		color: white;
   }
   
  }

  @media (min-width: 360px) and (max-width: 600px) {
	.bestgifts-banner{
		height: 25vh; 
		border: 0px solid white;
		width: 100%;
		background-size: cover;
		color: white;
   }
   #Agricultural-list{
	border-right:none !important ;
	}
	.vimpro_list_vacuum{
		font-size:8.5px;
	}

.b-radius{
	position: absolute !important;
    background: #FBCB0C !important;
    width: 20px  !important;
    height:20px  !important;
    border-radius: 10px  !important;
    top: -4px  !important;
    right: 40px  !important;
}
.f-increse{
	color: #fff!important;
    position: absolute!important;
    left: 0.2rem!important;
    top: 0.2rem!important;
    font-size: 14px!important;
    font-weight: 700!important;
}
.new_log{
	margin-left: 0px !important;
}

  }
.sort{
	margin-left: 80%;
	font-size: 13px;
}
.foot-text{
	width:100%;display:flex
}
.foot-icon{
	width:30%
}

@media screen and (max-width: 600px) {
	.footer-row-three1{
		height: 50px;
	}
	.foot-icon{
		width:325%
	}
	.foot-text{
		width:57%;display:flex
	}
	.sort{
	  margin-left: 50%;
       position: relative;
       top: -54px;
	   font-size: 12px;
	}
	
	.footer_logo{
		width: 94% !important;
		height: 24%!important;
		position: relative;
        top: -16px;
	}
	.email{
		border-radius: 50px 0px 0px 50px !important;
		border: 1px solid #ddd;
		padding: 10px;
		min-width:48%;
		width: 232%;
		margin-top: 2%;
		margin-bottom: 2%;
		height: 48%!important;
	}

	

}
.header_bestgift{
	position: relative;
	margin-top: 40px;
	z-index: 5;
	box-shadow: 0 2px 14px #0003, 0 4px 14px #00000030;

    margin-left: 15px!important;
    margin-right: 15px!important;



}
.banner_bestgifts{
	position: relative;
	margin-top: 40px;
}
.img_height{
	position: relative;
	margin-top: 20px;

}
@media screen and (max-width: 600px){
	.img_height{
		position: relative;
		margin-top: 20px;
	
	}
.profile_name{
	position:relative;
	margin-top: 3%;
}

.vimpro_link{
	margin:56px 0 93px;
	height: 90px;
}
}
.bestgiftslogo{
	position: relative;
	top: 58px;

}
.manager_content_info h4{
	font-size: 17px;
	// color:
}
.pwrdby{
	position: relative;top: 79px;right: 100px;

}
.gallery_top{
	background-color:#e8f1ff;;
	
}
@media (min-width: 640px){
	.sure_we {
		font-family: Brush Script MT,cursive;
		position: absolute;
		margin-left: 83%;
		margin-top: -9px;
		color: #e97418;
		font-size: 50px;
	}
}

@media (min-width: 990px) and (max-width:1400px){
	#exoffer-margin{
		margin-left: 7.5%;
        // margin-top:954px !important;
	}
	// .vimpro_video{
	// 	height: 100% !important;
	// 	position: relative;
	// 	left:331px;
	// 	object-fit: contain;
	// 	bottom:29px;
	// width: 160% !important;
	// }
	.about-us-md {
		width: 328px;
    height: 350px;
    padding: 0px 52px;
	}
	
}
@media (min-width: 1400px) and (max-width:1920px){
	#exoffer-margin{
		margin-left: 7.5%;
        // margin-top:1204px !important;
	}
	.vimpro_video{
		height: 100% !important;
		position: relative;
		left:396px;
		object-fit: contain;
		bottom:29px;
	width: 160% !important;
	}
	.about-us-md {
		width: 460px;
    height: 339px;
    padding: 0 112px;
	}
	#Contact_Persons{
		position: relative;
		margin-left: 362px;
		font-weight: 700;
		font-size: 17px;
	}

}
@media (max-width: 1920px)  {
	.bestgifts-banner{
		height: 45vh; 
		border: 0px solid white;
		width: 100%;
		background-size: cover;
		color: white;
   }
   
  }
  @media (min-width:1901px){
	#precise_size{
		margin-top:25px;
	}
	#Agricultural-list{
		
		border-right:none !important;
		}
	.new_arr1{
		font-size: 32px!important;
		text-align: center;
		font-weight: 700;
		white-space: nowrap;
		position: relative;
		margin-top: 38px;
		margin-left: 58px;
	  }
	  #exoffer-margin{
		margin-left: 7.5%;
		margin-top:954px !important;
	}
	#exoffer_12{
		max-width: 100%;
		height:457px;
		border: none!important;
	}
	#Versatility_size {
		margin-left: 74px!important;
		font-size: 27px!important;
		position: relative;
		margin-top: 12px;
	}
	#accuracy_button {
	background-color: #000!important;
    border: black!important;
    position: relative;
    margin-left: 118px;
	}
	.sure_we_can{

    position: absolute;
    margin-left: 77%;
    margin-top: -9px;
    color: #fff;
    font-family: cursive;
    font-size: 45px;
	}
	

	#Contact_Persons{
		position: relative;
		margin-left: 516px;
		font-weight: 700;
		font-size: 17px;
	}
	#Babu_contact{
		position: relative;
		margin-left: 18%;
		margin-top: 3%;
	}
	#Arvind_babu_contact{
		position: absolute;
		margin-left: 64.6%;
		margin-top: -7%;
	
	}
	#Gautham_babu_contact{
		position: relative;
		margin-top: 6%;
		margin-left: 18%;
	}
	#Srinivasan_contact{
		position: relative;
		margin-left: 67%;
		margin-top: -7%;
	}

	
  }

  @media (min-width:1500px) and (max-width:1700px){
	

#Contact_Persons{
	position: relative;
    margin-left: 413px;
    font-weight: 700;
    font-size: 17px;
}
#Babu_contact{
	position: relative;
    margin-left: 18%;
    margin-top: 5%;
}
#Arvind_babu_contact{
	position: absolute;
	margin-left: 60.6%;
    margin-top: -9%;

}
#Gautham_babu_contact{
	position: relative;
    margin-top: 7%;
    margin-left: 18%;
}
#Srinivasan_contact{
	position: relative;
	margin-left: 63.5%;
    margin-top: -9.1%;
}




  }

  @media (min-width:1701px) and (max-width:1900px){


	#Contact_Persons{
		position: relative;
		margin-left: 459px;
		font-weight: 700;
		font-size: 17px;
	}
	#Babu_contact{
		position: relative;
		margin-left: 18%;
		margin-top: 3%;
	}
	#Arvind_babu_contact{
		position: absolute;
		margin-left: 64.6%;
		margin-top: -7%;
	
	}
	#Gautham_babu_contact{
		position: relative;
		margin-top: 6%;
		margin-left: 18%;
	}
	#Srinivasan_contact{
		position: relative;
		margin-left: 67%;
		margin-top: -8%;
	}
	
  }


  @media (min-width:1400px) and (max-width:1900px){
	#Agricultural-list{
		border-right:none !important;
		}
		.sure_we_can{

			position: absolute;
			margin-left: 74.1%;
			margin-top: -5px;
			color: #fff;
			font-family: cursive;
			font-size: 39px;
		}
		
	#exoffer_12{
		max-width: 100%;
		height:434px;
		border: none!important;
	}

	#exoffer-margin{
		margin-left: 7.5%;
		margin-top:700px !important;
	}
	#precise_size{
		margin-top:49px;
	}
	.about_info_content{
		line-height: 27px;
		text-align: justify !important;
	}
	.new_arr1{
		    font-size: 32px!important;
    text-align: center;
    font-weight: 700;
    white-space: nowrap;
    position: relative;
    margin-top: 49px !important;
	margin-left: 7px;
	  }

	  #Versatility_size{
	    margin-left: 30px!important;
    font-size: 26px!important;
    position: relative;
    margin-top: -4px;
   }
   #accuracy_button{
	background-color: #000!important;
    border: black!important;
    position: relative;
    margin-left: 115px;
}






  }


//   @media (min-width:300px) and (max-width:360px) {
// 	.sec_top {
// 		position: relative;
// 		top:298px!important;
// 	}

//   }
// @media screen and (min-width:360px){
// 	.sec_top {
// 		position: relative;
// 		top:298px!important;
// 	}
// }

@media screen and (max-width: 600px) {

	.carousel-indicators li{
		padding: 0px 7px;
	}

	#precise_size{
		margin-top:2vh;
	}
	.sec_top {
		position: relative;
		top:114px;
	}
	.gallery_top{
		margin-top: 131px;
	}
	.lap_top{
		margin-top: 330px;

	}
	#precies12{
		max-width: 540px;
		height: 344px;
		border: none!important;
	}
	#exoffer_12{
		max-width: 100%;
		height: 346px;
		border: none!important;
		margin-left: 2%;
	}
#precies1{
	max-width: 100%;
    height: 336px;
    border: none!important;
}


	.banner-img2{
		width: 90%;
		height: 100px!important;
		margin-left: -45%;
		border: 1px solid #ccc;
	  }
	.tab-label-vim {
		position: relative;
		display: block;
		line-height: 1.5em!important;
		height: 1.5em!important;
		padding: 0 0.618em!important;
		
		border-right: 0.125rem solid black!important;
		
		cursor: pointer;
		
	}
	.tab-label-vim:active {
		color:#00A36C!important
		
		
	}
	.gallery_img {
		position: relative;
		height: 99% !important;
		width: 142%!important;
		left: -9px;
		object-fit: contain;
		box-shadow: 0 2px 3px #0f42b6c4, 0 4px 9px #486fc8e8;
	}
	.mob-font{
		font-size: 15px!important;
	}
	
	.btn_size1 {
		width: 47%!important;
		min-height: 29px!important;
		max-height: 81px!important;
		position: relative!important;
		top: -6px!important;
		left: 54px!important;
		font-size: 12px!important;
	}
	.bestgiftslogo{
		position: relative;
		height: 70;
		top: 100px;
		right: 90px;
		min-width: 22%;
		min-height: 20%;
	
	}
	.manager_section {
		background-color: #e8f1ff;
		height: 495px;
		margin: 16px 8px;
		border-radius: 6px;
		box-shadow: 1px 2px 15px #ccc;
	}
	.pwrdby{
		position: relative;
    top: 99px;
    white-space: nowrap;
    right: 196px;
	
	}
	.desktop-only{
		display: block;
	}
	.new_arr{
		font-size: 12px!important;
		text-align: center;
		
	  }
	  .new_arr1{
		font-size: 15px!important;
		text-align: center;
		font-weight: 700;
		white-space: nowrap;
		position: relative;
		// margin-top: 5px;
		// margin-left: -8px;
	  }

#Versatility_size{
    font-size: 12px !important;
    padding: 1px 1px;
    margin: 1px 10px;
}
#accuracy_button{
	background-color: black!important;
	 border: black!important;
	 margin: 11px 8px !important;
}

	  .btn_size{
		width: 80%;
		min-height: 10px!important;
        max-height: 35px!important;

	}
	
	.container_home{
		margin-top: 2px;
		// margin-left: 53px;
		margin-left: -9px!important;
	}
	


}


.gallery_img{
	max-width: 300px;
    max-height: 185px;
	box-shadow: 0 2px 3px #0f42b6c4, 0 4px 9px #486fc8e8;
}

@media (min-width: 640px) {
	.vim_banner{
	  bottom: 50px !important;
	  position: relative;
  }
	}
	@media (max-width: 640px) {
	  .vim_banner{
		  bottom: 0px !important;
	  }
	}
.btn_size1{
	width: 100%;
	min-height: 46px;
	max-height: 50px;
	position:relative;
	top: 20px;

}
.sec_top{
	position: relative;
}
.footer_logo{
	height: 50%;
}
.email{
	border-radius: 50px 0px 0px 50px !important;
	border :1px solid #ddd;padding:10px;
	width: 65%;margin-top: 2%; 
	margin-bottom: 2%;
	height: 60%
}
.vimpro-head{
	margin-left: 10px;
}
.img_shop{
	width: 100%;
	height: 100%;
              
	margin-top: 55px;
}
.ht_home_category{
	height:auto;
}
.vimpro_imghome{
max-width: 47% !important;
}
@media screen and (max-width: 600px){
	.img_shop{
		max-width: 234px !important;
    width: 190%;
	}
	.banner_image_vimpro{
		width:100%;
		height:9rem!important;
		
	}
	
	.h_font_title{
		font-size: 12px;
	}
	.vimpro_imghome{
		max-width: 100% !important;
	}
	.align_left_vimpro{
		max-width: 100% !important;
		width: 171% !important;
		position: relative;
		left: 0px!important;
	
	}
	.exoffer{
		background-color: whitesmoke;position: relative;margin-left: 10%;position:relative;margin-top: 120vw!important;
	}
	.vimpro-head{
		display: block !important;
	}
	
	#quantity_button{
		min-width: -webkit-fill-available;
		margin-top:1vw;
		margin-right:59px;
	}
	.carience-container{
		display: grid;
		grid-template-columns: repeat(1, 1fr);
	}
	#dropdownMenuButton
	{
		font-size:12px !important;
	}
	.cariencetitle
	{
		height: 25px;
	}
	.carience-strikethrough{
		font-size: 12px !important;
		margin-left: 5px !important;
		text-decoration: line-through;
		color: grey !important;
	}
	.carience-listing-price{
		font-size: 12px;
		color: black;
		font-weight: 700;
		position: relative;
		top:4px;
	}
	.carience-listing-price-1{
		font-size: 12px;
		color: black;
		font-weight: 700;
		position: relative;
		top:0px;
	}

.carience-image-container-1{
	
	min-width: 39vw;
    max-width: 73vw;
    min-height: 66vw;
    max-height: 36vw;
    margin: -3px 53px;
}



	.bullet_point
  {
    display:none;
  }
.vimpro_head{
  font-weight: 750;
  font-size: 10px;
  height: 8px;
}
.vimpro_foot{
  font-family: math;
  font-size: 9px;
}
	.button-62 {
		font-size: 3vw;
    margin-top:1vw;
	position: relative;
	margin-right: 44px;
		//font-size: 10px !important;
	}
	.button-63 {
		font-size: 3vw;
		margin-top:2vw;
		position: relative;
		margin-right:84px;
	}
	.colour-image{
		width: 100%;
		border: 1px solid;
		height: 30px;
	}
	.carience-card{
		width: 97vw;
		height: 51vw;
		display: flex;
		flex-wrap: wrap;
		margin: 5px -5px;
	}
	.carience-image-container {
		 min-width: 37vw;
		max-width: 51vw;
		min-height: 36vw;
		max-height: 36vw;
		padding: 1vw;}


.add-card-list{
	width: 190px !important;
	margin: 3px -6px;
}



		.avail_colors{
			font-family: cursive;
			  margin-top: 16px !important;
			  margin-bottom: 14px !important;
			  display: flex;
			  align-items: baseline;
		   }
		   .avl_label{
			font-weight: 700;
			font-size: 9px !important;
		   }    
		   .avl_text{
			font-size: 9px !important;
		  }
		   .vimpro_variable_false{
			display:flex;margin-bottom: 1px;
		   }
}
.scroll_fn{
    font-size: 14px !important;
    position: relative;
    top: 80px;
    left: -290px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;
}
.hide_cate{
	display: none;
}
.v_cate_top{
	font-size: 16px !important;
    position: relative;
    top: 76px;
    left: -329px;
    border-top-left-radius: 10px!important;
    border-top-right-radius: 10px!important;
    
    max-width: 150%;
    min-width: 150%;
    max-height: 100%;
    min-height: 110%;
}
.vim_dropdown{
	width:29%!important;
}
.exoffer{
	background-color: whitesmoke;position: relative;margin-left: 10%;position:relative;margin-top:36vw;
}
.align_left_vimpro{
	max-width: 50%;
	position: relative;
	left:88px;

}
.pro_list_color{
	color: #2554c7!important;

}
.vim_top_cate{

	background-color: #e97418;
}

.vim_top_cate:hover{

	background-color: #2554c7!important;
}

